/** @jsx jsx */

import { jsx } from 'theme-ui'
import { useInView } from 'react-hook-inview'

const FadeInWrapper = ({ children, threshold = 0.5, forwardSx = {} }) => {
  // Personal fun note -- Threshold is essentially a value between 0 & 1 that represents how much of the element that the intersection Observer's ref is on has to be visible before it's "intersecting"
  // This value we uppsed a bit because if it's set to 1 it takes until the full (at that point invisible)  element is visible before fading in
  const [ref, isVisible] = useInView({
    threshold: threshold,
    unobserveOnEnter: true
  })

  return (
    <div
      sx={{
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 300ms ease-in',
        ...forwardSx
      }}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default FadeInWrapper
