import React, { useEffect, useState } from 'react'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useColorMode, Flex } from 'theme-ui'
import PropTypes from 'prop-types'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ConfirmationPage from '~/components/Gift/ConfirmationPage'
import Spinner from '~/components/Generic/Spinner'

const Confirmation = ({ location }) => {
  const [, setColorMode] = useColorMode()
  const translate = useTranslate()
  const { finalizeCheckout } = useCart()
  const [cart, setCart] = useState()

  const params = new URLSearchParams(location.search)
  const orderNumber = params.get('number') || ''

  const getCartData = async () => {
    const response = await finalizeCheckout({ orderNumber })
    setCart(response)
  }

  useEffect(() => {
    setColorMode('Gift-Flow-Form')
    getCartData()
  }, [])

  return (
    <Layout>
      <Metadata title={translate('gifting_flow.confirmation.gift_redeemed')} />
      {!cart ? (
        <Flex sx={{ justifyContent: 'center', margin: '100px 0 200px 0' }}>
          <Spinner size={100} />
        </Flex>
      ) : (
        <ConfirmationPage cart={cart} />
      )}
    </Layout>
  )
}

export default Confirmation

Confirmation.propTypes = {
  location: PropTypes.object
}
